<template>
  <div v-if="send" class="forget">
    <p class="_title">忘记密码了?</p>
    <p class="desc">
      请输入连接到您帐户的电子邮件地址，我们将向您发送一个链接以重置您的密码。
    </p>
    <v-form ref="form" v-model="valid" lazy-validation :style="width">
      <div class="d-flex justify-center align-center">
        <div class="label mr-3" style="width: 80px; text-align: end">
          Email：
        </div>
        <v-text-field
          v-model="user.email"
          :rules="rules.email"
          required
        />
      </div>

      <v-btn
        style="min-width: 100 !important"
        :disabled="!valid"
        color="success"
        block
        class="mt-4"
        @click="registerAction"
      >
        提交邮箱
      </v-btn>
    </v-form>
  </div>
  <div v-else class="forget">
     <div class="_success">
        <img src="../../assets/img/icon/11.png" alt="">
        <p class="_title">已发送邮件到您的邮箱中,请前往您的邮箱查看！</p>
     </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      valid: true,
      send:true,
      user: {},
      rules: {
        required: (value) => !!value || "请输入",
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  watch: {},
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width:100%";
        case "sm":
          return "width:100%";
        case "md":
          return "width:40%";
        case "lg":
          return "width:40%";
        case "xl":
          return "width:40%";
      }
    },
  },
  created() {},
  methods: {
    registerAction() {
       this.$request({
        url: '/user/forget',
        method: 'post',
        data:{email:this.user.email}
      }).then((res) => {
        this.send=false
        console.log(res)
      })
    },
  },
};
</script>

<style lang="less" scoped>
.forget {
  padding: 0 10px;
  padding-bottom: 200px;
  ._success{
    text-align: center;
  }
  ._title {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 10px;
  }
  .desc {
    margin-left: 10px;
  }
  .v-btn--block {
    min-width: 0px !important;
    width: 300px;
    margin: 0 auto;
  }
}
</style>
